import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Chip } from "@mui/material";
import { type Agent } from "@src/appV2/Agents";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";

import { ShiftBlockFilterDialog } from "./ShiftBlockFilterDialog";

interface ShiftBlockFilterProps {
  agent: Agent;
}

export function ShiftBlockFilter(props: ShiftBlockFilterProps) {
  const { agent } = props;

  const filterModalState = useModalState();
  const { filters, setFilters } = useAgentPreferences();
  return (
    <>
      <Box>
        {isDefined(agent.licensesData) && isDefined(filters) && isDefined(filters.license) && (
          <Chip
            color="primary"
            aria-label="Update License"
            label={<b>{filters.license}</b>}
            onClick={() => {
              filterModalState.openModal();
            }}
          />
        )}
      </Box>
      {isDefined(filters) && (
        <ShiftBlockFilterDialog
          modalState={filterModalState}
          agentLicenses={agent.licensesData}
          defaultValues={filters}
          onSubmit={setFilters}
        />
      )}
    </>
  );
}
